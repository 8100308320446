import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Projects = () => {
  const { projectsWithImgs, projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Projects" />
          {projects.map((row) => {
            return (
              <Row key={row[0].id}>
                <Col lg={6} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">
                        {row[0].title || 'Project Title'}
                      </h3>
                      <div>
                        <p>{row[0].info || ''}</p>
                        <p className="mb-4">{row[0].info2 || ''}</p>
                      </div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={row[0].url || '#!'}
                      >
                        {row[0].linkName || 'See Live'}
                      </a>

                      {row[0].repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={row[0].repo}
                        >
                          {row[0].repoName || 'Source Code'}
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                {row[1] && (
                  <Col lg={6} sm={12}>
                    <Fade
                      left={isDesktop}
                      bottom={isMobile}
                      duration={1000}
                      delay={500}
                      distance="30px"
                    >
                      <div className="project-wrapper__text">
                        <h3 className="project-wrapper__text-title">
                          {row[1].title || 'Project Title'}
                        </h3>
                        <div>
                          <p>{row[1].info || ''}</p>
                          <p className="mb-4">{row[1].info2 || ''}</p>
                        </div>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn cta-btn--hero"
                          href={row[1].url || '#!'}
                        >
                          {row[1].linkName || 'See Live'}
                        </a>

                        {row[1].repo && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cta-btn text-color-main"
                            href={row[1].repo}
                          >
                            {row[1].repoName || 'Source Code'}
                          </a>
                        )}
                      </div>
                    </Fade>
                  </Col>
                )}
              </Row>
            );
          })}
          <Row>
            <Col xs={12}>
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
                <div className="project-wrapper__text">
                  <h3 className="project-wrapper__text-title">Pokefolio</h3>
                  <div>
                    <p>
                      A portfolio tool for tracking the total value of your Pokemon card collection.
                      Prices are collected based on recently sold prices on Ebay and other card
                      tracking data sources. The information is presented in a dashboard view and
                      tracks prices and portfolio value over time.
                    </p>
                    <p className="mb-4">
                      The project makes use of a Rails backend API and React frontend. One day I'll
                      get around to finishing this..
                    </p>
                  </div>
                  <a rel="noopener noreferrer" className="cta-btn cta-btn--hero" href="#!">
                    Coming soon...
                  </a>
                </div>
              </Fade>
            </Col>
          </Row>
          {projectsWithImgs.map((project) => {
            const { title, info, info2, url, repo, img, id, repoName, linkName } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div>
                        <p>
                          {info ||
                            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
                        </p>
                        <p className="mb-4">{info2 || ''}</p>
                      </div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url || '#!'}
                      >
                        {linkName || 'See Live'}
                      </a>

                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          {repoName || 'Source Code'}
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <a
                        href={url || '#!'}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      >
                        <div className="thumbnail rounded">
                          <ProjectImg alt={title} filename={img} />
                        </div>
                      </a>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
